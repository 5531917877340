import { call, put, select, takeLatest } from 'redux-saga/effects';
import { compact, values } from 'lodash';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';
import { trackEvent } from 'app/redux/modules/Analytics/actions';
import putSelectedBenefit from 'redux/modules/GoldBenefit/service/putSelectedBenefit';
import isEligibleForGoldBenefit from 'redux/modules/GoldBenefit/service/isEligibleForGoldBenefit';
import { eligibilityResponse } from 'redux/modules/Eligibility/actions';
import { BENEFIT_HOME_PHONE_BROADBAND } from 'redux/modules/GoldBenefit/constants';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { editOrderDone } from 'redux/modules/App/actions';
import acceptEnergyQuotes from 'redux/modules/Energy/Quote/service/putAcceptEnergyQuotes';
import {
  setServices,
  addUpsellServices,
} from 'redux/modules/ServiceSelection/actions';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { transformProducts } from 'app/redux/modules/OrderSummary/presentation';
import { getApplicationSummary, postValidateBasket } from '../service';
import * as types from '../types';
import * as actions from '../actions';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';
import { PRODUCT_BROADBAND } from '../../Bundles/constants';
import { PREPAYMENT } from '../constants';

// apply "gold" benefit to application
// only Homephone BB benefit, what is this used for?
export function* checkGoldEligibility() {
  let goldEligible;
  try {
    ({ data: goldEligible } = yield call(isEligibleForGoldBenefit));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_GOLD_BENEFITS_ELIGIBILITY);
    const errorMessage = `We encountered an error while checking your eligibility for the benefits. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }

  if (goldEligible) {
    try {
      yield call(putSelectedBenefit, BENEFIT_HOME_PHONE_BROADBAND);
    } catch (error) {
      Sentry.log(error, ERRORS.UPDATE_GOLD_BENEFITS);
      const errorMessage = `We encountered an error while saving your benefits. ${error}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  }
}

// generate quote change structure, for displaying
// if an energy quote changed due to alterations in
// application product request
export function normalizeChanges(changes) {
  const newChanges = {
    products: {
      to: {},
      from: {},
    },
  };

  const { energy } = changes;
  if (!energy) return null;
  const { gas, electricity } = energy;

  if (!gas && !electricity) {
    return null;
  }

  if (electricity) {
    newChanges.products.from.electricity = {
      quote: { ...electricity.from },
    };
    newChanges.products.to.electricity = {
      quote: { ...electricity.to },
    };
  }

  if (gas) {
    newChanges.products.from.gas = {
      quote: { ...gas.from },
    };
    newChanges.products.to.gas = {
      quote: { ...gas.to },
    };
  }

  return newChanges;
}

export function formatEnergyQuotes(energyQuotes) {
  if (!energyQuotes) {
    return null;
  }

  const prepaymentQuotes = energyQuotes.filter(
    ({ tariff }) => tariff.paymentMethod === TARIFF_TYPES.PREPAYMENT
  );

  if (prepaymentQuotes.length) {
    return null;
  }

  const groupedQuotes = [
    energyQuotes.filter((quote) => quote.tariff.type === TARIFF_TYPES.FIXED),
    energyQuotes.filter((quote) => quote.tariff.type === TARIFF_TYPES.VARIABLE),
  ];

  const formattedQuotes = groupedQuotes.map((quotes) => {
    const electricity = quotes.find((quote) => quote.type === 'ELECTRICITY');
    const gas = quotes.find((quote) => quote.type === 'GAS');
    const formattedQuote = {};
    if (electricity) {
      formattedQuote.electricity = {
        quote: { ...electricity },
      };
      formattedQuote.type = electricity.tariff.type;
    }
    if (gas) {
      formattedQuote.gas = {
        quote: { ...gas },
      };
      formattedQuote.type = gas.tariff.type;
    }
    return formattedQuote;
  });
  return formattedQuotes;
}

function isPrepayment(products) {
  return (
    products.electricity?.quote?.tariff?.paymentMethod === PREPAYMENT ||
    products.gas?.quote?.tariff?.paymentMethod === PREPAYMENT
  );
}

export const getFixedQuotesSummary = (quotes) => {
  if (!quotes) {
    return null;
  }

  return quotes.reduce(
    (summary, { tariff: { code }, budgetPlan: { value } }) => {
      if (code === 'FXV') {
        summary.fixedQuotes.value += value;
      } else if (code === 'FXS') {
        summary.fixedSaverQuotes.value += value;
      }
      return summary;
    },

    {
      fixedQuotes: {
        currency: 'GBP',
        exponent: 2,
        value: 0,
      },
      fixedSaverQuotes: {
        currency: 'GBP',
        exponent: 2,
        value: 0,
      },
    }
  );
};

export function* addNewlySelectedProduct() {
  const editInProgress = yield select(ApplicationApi.getEditInProgress);
  const editingNewService = yield select(ApplicationApi.getEditingNewService);
  if (editInProgress && editingNewService) {
    const application = yield select(OrderSummaryApi.getApplication);
    const transformedProducts = yield call(transformProducts, application);
    const selectedProductIds = compact(values(transformedProducts)).map(
      ({ id }) => id
    );
    const serviceInEdit = yield select(ApplicationApi.getEditService);
    const hasTBYBPromo =
      selectedProductIds.includes(PRODUCT_BROADBAND) &&
      transformedProducts.broadband?.discountedPrice?.value === 0;
    // check relevant when adding an add-on, e.g. cashback
    // since it's not listed on the application products list
    if (selectedProductIds.includes(serviceInEdit)) {
      yield put(addUpsellServices([serviceInEdit]));
    }
    if (
      selectedProductIds.length === 2 &&
      serviceInEdit !== PRODUCT_BROADBAND &&
      selectedProductIds.includes(PRODUCT_BROADBAND) &&
      hasTBYBPromo &&
      serviceInEdit !== 'cashback'
    ) {
      yield put(actions.setTbybModal(true));
    }
  }
  yield put(editOrderDone());
}

export function* handleGetApplicationSummaryRequest(action) {
  yield call(checkGoldEligibility);
  try {
    if (action.isApplicationComplete) {
      let { data } = yield call(
        getApplicationSummary,
        action.acceptedCreditCheck
      );

      yield put(actions.getSummaryRequestSuccess(data));
    } else {
      const { data } = yield call(postValidateBasket);
      let { application, changes, message, energyQuotes } = data;

      yield put(eligibilityResponse(application.eligibility));
      yield put(actions.setIsPrepayment(isPrepayment(application.products)));
      const fixedQuotesSummary = getFixedQuotesSummary(
        application.quotes.quotes
      );
      yield put(actions.setFixedQuotesSummary(fixedQuotesSummary));

      const normalizedChanges = yield call(normalizeChanges, changes);
      if (normalizedChanges) {
        yield put(
          actions.setRemoveProductModal(
            true,
            null,
            normalizedChanges,
            message,
            formatEnergyQuotes(energyQuotes)
          )
        );
      }
      yield put(actions.getApplicationSuccess(application));

      const requestedServices = yield select(
        OrderSummaryApi.getMappedRequestedServices
      );
      yield put(setServices(requestedServices));
      yield call(addNewlySelectedProduct);

      const appPresentedState = yield select(
        analyticsSelectors[EVENTS.APPLICATION_SUMMARY_PRESENTED]
      );
      yield put(
        trackEvent(EVENTS.APPLICATION_SUMMARY_PRESENTED, appPresentedState)
      );
    }
  } catch (error) {
    Sentry.log(error, ERRORS.GET_APP_SUMMARY);
    yield put(actions.getSummaryRequestFailure(error));
    const errorMessage = `We encountered an error while retrieving your application summary. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleAcceptEnergyQuote(action) {
  try {
    const response = yield call(acceptEnergyQuotes, action.quoteIds);
    yield put(actions.acceptEnergyQuotesSuccess(response));
    yield put(actions.getSummaryRequest());
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_ENERGY_ACCEPT_QUOTE);
    yield put(actions.acceptEnergyQuotesFailure(error));
    yield put(actions.getSummaryRequest());
    const errorMessage = `We encountered an error while saving the quote. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* combinedSagas() {
  yield takeLatest(
    types.GET_APPLICATION_SUMMARY_REQUEST,
    handleGetApplicationSummaryRequest
  );
  yield takeLatest(types.ACCEPT_ENERGY_QUOTE, handleAcceptEnergyQuote);
}
